import { useEffect, useState } from "react";
import { ClassroomNavigation } from "../components/ClassroomNavigation/ClassroomNavigation";
import { ClassRoomProvider } from "../contexts/ClassRoomContext";
import { ICourse } from "../types/ICourse";
import { ILesson } from "../types/ILesson";
import { Courses } from "../services/api/CoursesRequests";
import { useNavigate, useParams } from "react-router-dom";
import { ClassroomContent } from "../components/ClassroomContent/ClassroomContent";
import { IoMenu } from "react-icons/io5";
import { useMediaQuery } from "../hooks/MediaQuery";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../hooks/Auth";

export const Lesson = () => {
  const { course_id, lesson_id } = useParams();
  const [course, setCourse] = useState<ICourse>();
  const [lesson, setLesson] = useState<ILesson>();
  const matches = useMediaQuery('(min-width: 640px)')
  const [navigation, setNavigation] = useState<boolean>(false)
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const locale = i18n.language;

useEffect(() => {
  setNavigation(matches)
},[matches])

  useEffect(() => {
    if (course_id) {
      Courses.FindById(parseInt(course_id)).then((data) => {
        setCourse(data);
      }).catch(() => {
        toast.error(t('commons.toast.error'));
      })
    }
  }, [course_id]);

  useEffect(() => {
    if (lesson_id) {
      const searchLesson = course?.modules
        ?.flatMap((module) => module.lessons)
        .find((lesson) => lesson.id === parseInt(lesson_id));
      setLesson(searchLesson);
    }
  }, [course, lesson_id]);

  useEffect(()=> {
    !matches && setNavigation(false);
  },[lesson_id, matches])

  if(user.blocked) {
    navigate(`/${locale}/courses`);
  } 

  return (
    <>
      {lesson && (
        <ClassRoomProvider currentLesson={lesson}>
          <div className="px-4 py-5 md:px-5 md:py-6">
            {course && navigation && <ClassroomNavigation course={course} />}
            <ClassroomContent />
          </div>
          <button type="button" onClick={() => setNavigation(!navigation)} className="md:hidden fixed bottom-4 right-4 w-12 h-12 flex items-center justify-center rounded-full bg-teal-500 shadow-lg z-50 text-white ">
            {navigation ? <IoClose /> : <IoMenu />}
          </button>
        </ClassRoomProvider>
      )}
    </>
  );
};
